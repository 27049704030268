import React from 'react'
import { Helmet } from 'react-helmet-async';

const SEO = ({title, description, name, type,image,url,href}) => {
  const noHrefLangs = () => (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} /> */}

      <link rel='canonical' href={url} />
    </Helmet>
  );
  const withHrefLangs = () => (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} /> */}

      <link rel='canonical' href={url} />
      <link rel="alternate" hreflang="en-ae" href="https://www.yellowdoorenergy.com/markets/uae"/>
      <link rel="alternate" hreflang="en-jo" href="https://www.yellowdoorenergy.com/markets/jordan"/>
      <link rel="alternate" hreflang="en-za" href="https://www.yellowdoorenergy.com/markets/south-africa"/>
      <link rel="alternate" hreflang="en-sa" href="https://www.yellowdoorenergy.com/markets/saudi-arabia"/>
      <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/bahrain"/>
      <link rel="alternate" hreflang="en" href="https://www.yellowdoorenergy.com/markets/oman"/>
    </Helmet>
  );
  return (
    <>
      {href == 1 ? withHrefLangs() : noHrefLangs()}
    </>
  )
}

export default SEO