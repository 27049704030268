import React from 'react'
import Benefits from '../components/Benefits'
import Line from '../shapes/Line'
import { useDispatch, useSelector } from 'react-redux'
import { useState , useEffect } from 'react'
import { getApp } from '../features/applications/applicationSlice'
import { Link, useParams , useNavigate } from 'react-router-dom'
import StripTags from '../utils/StripTags';
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import SEO from '../components/SEO'
const ProductApplication = () => {

    const [products,setProducts] = useState({});
    const [details,setDetails] = useState([]);
    const [benefits,setBenefits] = useState([]);
    const [banner,setBanner] = useState({});
    const [works,setWorks] = useState([]);
    const [projects,setProjects] = useState([]);
    const [seoTitle,setSEOTitle] = useState('');
    const [seoDescription,setSEODescription] = useState('');
    const [headerTitle,setHeaderTitle] = useState('');


    const dispatch = useDispatch();
    const {slug} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const data = await dispatch(getApp(slug))
            setDetails(data?.payload?.details)
            setBenefits(data?.payload?.benefits)
            setBanner(data?.payload?.banner)
            setWorks(data?.payload?.works)
            setProducts(data?.payload?.products)
            setProjects(data?.payload?.projects)
   
            if(data?.error) {
                navigate('/404')
            }
        }
        
        fetchData();
     
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if(window.location.pathname == '/solutions/solar-photovoltaic-pv') {
            setSEOTitle('Solar PV | Rooftop | Carport | Ground-mount | Yellow Door Energy');
            setSEODescription("Decarbonize your operations with our solar solutions! From rooftop, carport to ground-mount PV, we've powered 220+ MW projects in the Middle East and South Africa.");
            setHeaderTitle('Solar Photovoltaic');
        } else if(window.location.pathname == '/solutions/battery-energy-storage-system') {
            setSEOTitle('Battery Energy Storage System | BESS | Yellow Door Energy');
            setSEODescription("Our hybrid battery energy storage systems (BESS) are ideal for areas with unreliable power. Our solutions ensure uninterrupted operations, combating load shedding & demand shifting challenges.");
            setHeaderTitle('Battery Energy Storage System');
        } else if(window.location.pathname == '/solutions/electric-vehicle-charging-system') {
            setSEOTitle('Electric Vehicle (EV) Charging System | Yellow Door Energy');
            setSEODescription("Upgrade to new electric vehicles and power your charging stations with clean solar energy. Drive sustainability forward with our comprehensive EV solution.");
            setHeaderTitle('EV Charging System');
        } else if(window.location.pathname == '/solutions/led-lighting-&-equipment-retrofit') {
            setSEOTitle('LED Street Lighting And Equipment Retrofit | Yellow Door Energy');
            setSEODescription("Upgrade to LED Street Lighting, cut costs! Our Energy Savings Contracts reduce consumption, lower bills, with no upfront investment. Illuminate efficiently and sustainably.");
            setHeaderTitle('Led Street Lighting And Equipment Retrofit');
        } else if(window.location.pathname == '/solutions/bess-power-test-drive') {
            setSEOTitle('BESS Power Test Drive | Yellow Door Energy');
            setSEODescription("");
            setHeaderTitle('BESS Power Test Drive');
        } 


     
    },[dispatch,slug])

  return (
    <>
        <h1 className='heading-title'>{headerTitle}</h1>
        <SEO
            title={seoTitle}
            description={seoDescription}
            url={window.location.href}
        />
        <div className='mt-[80px] md:mt-[110px]'>
            <div className='relative lg:flex lg:h-[344px]'>
                <img width="1600" height="800" className='object-cover relative z-10 md:z-0 md:absolute top-0 left-0 h-full w-full md:w-[36%]' src={banner?.image} alt={banner?.title_en}  />
                <div className='lg:background-shape bg-primary-500 absolute top-0 right-0 h-full flex lg:justify-normal ml-auto lg:w-[76%] w-full'>
                </div>
                  <div className='header-section-pad relative flex w-full screen-1'>
                    <div className='lg:ml-auto lg:w-[70%] lg:pl-[30px]'>
                        <div className='w-full'>
                            <h1 className='uppercase text-[#ffffff] text-2xl lg:text-xl font-gotham-bold'>{banner?.title_en}</h1>
                        </div>
                        <div className='w-full'> 
                            <span className='text-[#ffffff] lg:text-base  2xl:text-lg my-3 inline-block '>
                              <StripTags policyText={banner?.text_en} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {details?.map((detail,id) => (
                <div className='screen-1 mt-4 lg:my-16'>
                <div className='grid md:grid-cols-3 mb-24'>
                    <div className='w-full flex flex-col col-span-1 lg:w-[75%]'>
                        <span className=' uppercase text-primary-500 2xl:text-3xl text-xl leading-none'>
                            WHAT IS
                        </span>
                        <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>
                            {detail?.title_en}
                        </span>
                        <div className='space-y-1 mt-2'>
                            <Line width={60} />
                            <Line width={120} />
                        </div>
                    </div>
                    <div className='mt-1 col-span-2'>        
                        <p key={id} className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                            <StripTags policyText={detail?.text_en} />  
                        </p>
                        {detail?.image && (
                            <figure className='mt-3 '>
                                <img width="550" height="300" className='w-[500px] h-full object-cover rounded-tr-[50px] rounded-bl-[50px] lg:rounded-tr-[100px] lg:rounded-bl-[100px]' src={detail?.image} alt={detail?.title_en}  />
                            </figure>
                        )}
                    </div>
                </div>
                </div>
                 ))}
                {/* Benefits */}
                <div className='-mt-12 lg:my-16'>
                <div className='screen-1'>
                    <div className='flex flex-col lg:flex-row lg:justify-between py-3 '>
                        <div>
                            <span className='uppercase text-primary-500 2xl:text-2xl  text-xl leading-none'>Your</span>
                            <div className='flex flex-col'>
                                <span className='uppercase  w-full text-primary-500 2xl:text-3xl  text-xl  font-gotham-black leading-none'>Benefits</span>
                                <div className='space-y-1 w-full mt-2'>
                                    <Line width={60} />
                                    <Line width={120} />
                                </div>
                            </div>
                        </div>
                        <div className='grid sm:grid-cols-2 gap-12 w-full mt-12 lg:mt-0 lg:w-[66.5%]'>
                            {benefits?.map(({text_en}, id) => (
                                <Benefits key={id} text={text_en} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className='screen-1 mb-20 mt-16 lg:my-16'>
            <div className='grid md:grid-cols-3 mb-24'>
                <div className='w-full flex flex-col col-span-1'>
                    <span className='uppercase text-primary-500 2xl:text-3xl text-xl leading-none'>
                        HOW DOES
                    </span>
                    <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>
                        IT WORK?
                    </span>
                    <div className='space-y-1 mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
                <div className='mt-1 col-span-2'>
                    {works?.map((work,id) => (
                        <p key={id} className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list'>
                            <StripTags policyText={work?.text_en} />
                        </p>
                    ))}
                </div>
            </div>
        </div>
        {/* Projects */}
        <section className='screen-1 mb-6 -mt-12 lg:my-16'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 gap-4'>
                    {projects?.map((project,id) => (
                            <>
                                {project?.isYoutube && (
                                    <div key={id} className='sm:col-span-2'>
                                            <div>
                                            <iframe
                                                title={project?.title_en}
                                                width='100%'
                                                height='300px'
                                                src={`https://www.youtube.com/embed/${project?.isYoutube.split('/').pop()}`}
                                                frameBorder='0'
                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                allowFullScreen
                                            ></iframe>
                                            </div>
                                    </div>
                                )}
                                {project?.isYoutube == null && (
                                    <>
                                        <div>
                                            <div>
                                                <Link to={`/projects/${project?.slug}`}>
                                                    <img width="329" height="182" className='w-full h-full object-contain' src={project?.image} alt={project?.title_en} />
                                                    <div className='py-4 sm:px-5'>
                                                        <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                                            {project?.title_en}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )} 
                                    
                                </>
                        ))}
                </div>
                </section>
        </div>

        <div className='mb-32'>
            <Contact />
        </div>

        <div className='mt-32'>
            <OurOffices />
        </div>

    </>
  )
}

export default ProductApplication