import React, { useEffect, useState } from 'react'
import Insta from '../assets/images/homescreen/insta-black.svg'
import LinkedIn from '../assets/images/homescreen/link.svg'
import Face from '../assets/images/homescreen/face.svg'
import Tweet from '../assets/images/homescreen/tweet-black.svg'
import Whats from '../assets/images/homescreen/whats.svg'
import Line from '../shapes/Line'
import Contact from '../components/Contact'
import OurOffices from '../components/OurOffices'
import { useDispatch, useSelector } from 'react-redux'
import {getProjects, getProject,getBenefits,getDescription } from '../features/projects/projectSlice'
import { Link, useParams } from 'react-router-dom'
import StripTags from '../utils/StripTags'
import moment from 'moment';
import LoadingComponent from '../components/LoadingComponent'
import config from '../config'
import SEO from '../components/SEO'

const InnerProject = () => {

    const { projects, project, benefits, descriptions , isLoading } = useSelector((state) => state.project);

    const [projectId,setProjectId] = useState(null);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };
 
    const dispatch = useDispatch();
    const {slug} = useParams();
    
    useEffect(() => {
        dispatch(getProjects());
        dispatch(getProject(slug));

        scrollToTop();
      }, [dispatch, slug]);
      
    useEffect(() => {
    if (project) {
        setProjectId(project?.project?.id);
    }
    }, [project]);
    
    useEffect(() => {
    if (projectId !== null) {
        dispatch(getBenefits(projectId));
        dispatch(getDescription(projectId));
    }
    }, [projectId, dispatch]);
    
 
    // Other Projects Filtering
    const excludedProjectIds = Number(projectId);
    const marketProjects = projects?.filter(project => project.market_id === projectId);
    const otherProjects = projects?.filter(project => project.market_id !== projectId);
    const displayedProjects = marketProjects.length > 0 ? marketProjects : [];
    const shuffledProjects = otherProjects.filter((project) => project.id !== excludedProjectIds).sort(() => Math.random() - 0.5);
    const finalProjects = displayedProjects.concat(shuffledProjects);


    const shareURL = encodeURIComponent(window.location.href);
    const title = encodeURIComponent(project?.title_en);
    const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`;
    const facebookShareLink = `https://www.facebook.com/sharer.php?u=${shareURL}`;
    const twitterShareLink = `https://twitter.com/intent/tweet?url=${shareURL}&text=${title}&hashtags=yellowdoorenergy`;
   
    function truncate(string,number)  {
        return string?.length > number ? string.substring(0,number-1)+ "..." : string
    }

  return (
    <>
    <SEO
        title={project?.project?.title_en + " | Yellow Door Energy"}
        description=""
        url={window.location.href}
        image={project?.project?.image}
    />
    {isLoading && (
        <div className={`loading-screen ${isLoading ? '' : 'hidden'}`}>
            <LoadingComponent />
        </div>
      )}
        <div className='screen-1 mt-[100px] lg:mt-[140px]'>
            <div className='grid md:grid-cols-2'>
                <div>
                    <img width="812" height="445" className='w-full h-full lg:h-[350px] object-cover' src={project?.project?.image} alt={project?.project?.title_en} />
                    <div className='flex space-x-4 lg:justify-end items-baseline my-4'>
                    <span>Share:</span>
                        <a href={linkedInShareLink} target="_blank">
                            <img width="15" height="15" className='object-contain w-[15px] h-[15px]' src={LinkedIn} alt='LinkedIn' />
                        </a>
                        <a href={facebookShareLink} target="_blank">
                            <img width="15" height="15" className='object-contain w-[15px] h-[15px]' src={Face} alt='Facebook' />
                        </a>
                        <a href={twitterShareLink} target="_blank">
                            <img width="15" height="15" className='object-contain w-[15px] h-[15px]' src={Tweet} alt='Twitter' />
                        </a>
                    </div>
                </div>
                <div className='flex flex-col justify-between mt-16 md:mt-0 lg:h-[350px]'>
                    
                    {/* {project?.project?.first_logo && (
                        <img width="259" height="215" className='md:w-[85%] md:mx-auto object-contain' src={project?.project?.first_logo} alt={project?.project?.title_en} />
                    )} */}
                   
                    {project?.project?.second_logo && (
                        <img width="259" height="215" className='md:w-[85%] md:mx-auto object-contain' src={project?.project?.second_logo} alt={project?.project?.title_en} />
                    )}
                        

                    <div className='text-primary-500 md:w-[85%] md:mx-auto'>
                        <h1 className='text-lg 2x:text-2xl font-gotham-bold'>{project?.project?.title_en}</h1>
                        <p><span className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>Capacity:</span> {project?.project?.mwp_en}</p>
                        <p><span className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>Location:</span> {project?.project?.location_en}</p>
                    </div>
                </div>
            </div>
        </div>
        <section className='screen-1 mt-8 md:mt-12 lg:mt-0 mb-8'>
        {benefits.length > 0 && (
           <>
            <div>
                <span className='uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Project</span>
                <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                    <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>Benefits</span>
                    <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
            </div>

            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-20 my-8'>
                {benefits?.map((benefit,id) => (
                    <div key={id} className='flex items-center mt-6 lg:mt-0'>
                        <img width="85" height="85" className='object-contain' src={benefit?.icon} alt={benefit?.text_en} />
                        <span className='text-[#101010 font-gotham-medium text-sm 2xl:text-lg ml-12'>
                            <StripTags policyText={benefit?.text_en} />
                        </span>
                    </div>
                ))}
            </div>

            {descriptions?.map((description, index) => (
                <div key={index} className='text-sm md:text-base 2xl:text-lg text-primary-500 custom-list leading-text mt-6'>
                    <StripTags policyText={description?.text_en} />
                    {description?.pdf && (
                    <div className='flex items-center py-6 space-x-10'>
                        <span className='text-[#101010] text-lg'>Download Case Study</span>
                        <button className='uppercase text-base 2xl:text-lg rounded-tl-[10px] font-gotham-medium rounded-br-[10px] text-primary-500 bg-secondary-500 hover:bg-primary-500 
                        w-[30%] lg:w-[15%] hover:text-secondary-500 border border-secondary-500 duration-300 ease-in'>
                        <a href={description.pdf} target='_blank' rel='noopener noreferrer'>PDF</a>
                        </button>
                    </div>
                    )}
                </div>
                ))}
            </>
        )}
        </section>
        <section className='screen-1'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-4'>
            {project?.galleries?.map((gallery,id) => (
                <>
                {gallery?.isYoutube && (
                    <div key={id} className='sm:col-span-2'>
                            <div>
                            <iframe
                                title={project?.title_en}
                                width='100%'
                                height='300px'
                                src={`https://www.youtube.com/embed/${gallery?.isYoutube.split('/').pop()}`}
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            ></iframe>
                            </div>
                    </div>
                )}
                {gallery?.isYoutube == null && (
                    <>
                        <div>
                            <div>
                                <a href={gallery?.image}  data-lightbox={`image-${id}`} data-title={`${gallery?.title_en}`}>
                                    <img width="" height="" className='w-full h-full object-contain' src={gallery?.image} alt='Project Galleries'  />
                                    <div className='py-4 sm:px-5'>
                                        <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                            {gallery?.title_en}
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </>
                )} 
                    
                </>
            ))}
        </div>
        </section>
        <section className='screen-1 mt-5'>
        <div className='grid lg:grid-cols-4 md:grid-cols-3  gap-4'>
            {project?.project?.insights?.map((insight,id) => (

                    <div key={id} className={`group lg:col-span-${id == 0 ? 2 : 1}`}>

                    <Link to={`/insights/${insight?.slug}`}>
                        <div><img width="329" height="182" className='w-full h-full object-contain' src={insight?.image} alt={insight?.title_en}  /></div>
                        <div className='py-4 sm:px-5'>
                            <p className='text-primary-500 font-gotham-bold uppercase 2xl:text-lg text-base group-hover:text-secondary-500 cursor-pointer duration-300 ease-in-out'>
                                {insight?.title_en}
                            </p>
                            <p className=' 2xl:text-base text-sm'>{insight?.insight?.title_en}</p>
                            <p className='text-xs text-primary-500 text-opacity-50  my-2'>{moment(insight?.date).format('LL')}</p>
                            <p className='2xl:text-base text-sm'><StripTags policyText={truncate(insight?.text_en,200)} /></p>
                            <div>
                                <Link to={`/insights/${insight?.slug}`} className='text-base hover:text-secondary-500 text-primary-500'>Learn More</Link>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
            </div>
        </section>

        {/* Other Projects */}
        <section className='screen-1 mt-8 mb-12'>
            <div>
                <span className=' uppercase text-primary-500 2xl:text-2xl text-xl leading-none'>Other</span>
                <div className='flex flex-col md:flex-row md:items-center md:space-x-6'>
                    <span className='uppercase  text-primary-500 2xl:text-3xl text-xl font-gotham-black leading-none'>Projects</span>
                    <div className='space-y-1 w-full mt-2 md:mt-0 2xl:mt-2'>
                        <Line width={60} />
                        <Line width={120} />
                    </div>
                </div>
            </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 pt-8 pb-4  gap-4'>
                {finalProjects?.slice(0,4).map((project,id) => (
                    <Link to={`/projects/${project?.slug}`} key={id}>
                        <div>
                            <div>
                                <img width="" height="" className='w-full h-full object-contain' src={project?.image} alt={project?.title_en} />
                                <div className='py-4 sm:px-5'>
                                    <p className='text-primary-500 font-gotham-bold uppercase text-sm 2xl:text-lg hover:text-secondary-500 duration-300 ease-in-out'>
                                    {project?.title_en}
                                    </p>
                                    <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.mwp_en}</p>
                                    <p className='font-gotham-bold text-sm 2xl:text-base text-primary-500'>{project?.location_en}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </section>

        <div className='mb-32'>
            <Contact />
        </div>

        <div className='mt-32'>
            <OurOffices />
        </div>
    </>
  )
}

export default InnerProject