import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';
import PopUp from './components/PopUp';
import { PopUpContext } from './context/popUpContext';
import Solutions from './pages/Solutions';
import Insights from './pages/Insights';
import Careers from './pages/Careers';
import Policies from './pages/Policies';
import OurTeam from './pages/OurTeam';
import SingleMember from './pages/SingleMember';
import Testimonial from './pages/Testimonial';
import OurValues from './pages/OurValues';
import Market from './pages/Market';
import Projects from './pages/Projects';
import InnerInsight from './pages/InnerInsight';
import InnerProject from './pages/InnerProject';
import Sustainability from './pages/Sustainability';
import OurStory from './pages/OurStory';
import ProductApplication from './pages/ProductApplication';
import ComingSoon from './pages/ComingSoon';
import Maintenance from './components/Maintenance';
import { useSelector } from 'react-redux'
import PrivacyPolicy from './pages/PrivacyPolicy';



function App() {
  const { popUp } = useContext(PopUpContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // Replace 'YOUR_ENV_USERNAME_VALUE' with the actual value of the environment variable 'username'
  const envUsername = process.env.REACT_APP_USERNAME || 'georges'; // Default value if env variable is not set
  const envPassword = process.env.REACT_APP_PASSWORD || '123';

  const storedUsername = localStorage.getItem('username');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    // Redirect to the main website if the username matches the env variable
    if (username === envUsername && password == envPassword) {
      setUsername('');
      setPassword('');
      localStorage.setItem('username', username); // Store username in localStorage
      window.location.href = '/'; // Redirect to the main website
    } else {
      console.log('Incorrect username:', username);
    }
  };

  useEffect(() => {
    // Function to clear username from localStorage after 3 hours
    const clearUsernameAfterThreeHours = () => {
      localStorage.removeItem('username');
    };

    // Check if username is already in localStorage and clear it after 3 hours

    if (storedUsername === envUsername) {
      const timer = setTimeout(clearUsernameAfterThreeHours, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
      return () => clearTimeout(timer); // Clear the timer on component unmount
    }
  }, []);

  const { isServerError } = useSelector((state) => state.error500);

  console.log(isServerError, 'serverrrr')

  if (isServerError) {
    return <Maintenance />
  }


  return (
    <>
      <Router>
        {storedUsername === envUsername && (
          <>
            <Navbar />
            <PopUp />
          </>
        )}

        <div className={`App popUp-container ${popUp ? 'active' : ''}`}>
          <Routes>
            <Route element={<ComingSoon handleSubmit={handleSubmit} handleChange={handleChange} />} path={'/coming-soon'} />
            {/* Use a guard to prevent access to other routes when the username is not provided */}
            {storedUsername === envUsername ? (
              <>

                <Route element={<Home />} path={'/'} />
                <Route element={<Solutions />} path={'/solutions/PPA-power-purchase-agreement'} />
                <Route element={<Insights />} path={'/insights'} />
                <Route element={<Careers />} path={'/careers'} />
                <Route element={<Policies />} path={'/policies'} />
                <Route element={<OurTeam />} path={'/teams'} />
                <Route element={<PrivacyPolicy />} path={'/privacy-policy'} />
                <Route element={<SingleMember />} path={'/teams/:slug'} />
                <Route element={<Testimonial />} path={'/testimonials'} />
                <Route element={<OurValues />} path={'/our-values'} />
                <Route element={<Market />} path={'/markets/:slug'} />
                <Route element={<Projects />} path={'/projects'} />
                <Route element={<InnerInsight />} path={'/insights/:slug'} />
                <Route element={<InnerProject />} path={'/projects/:slug'} />
                <Route element={<Sustainability />} path={'/sustainability'} />
                <Route element={<OurStory />} path={'/our-story'} />
                <Route element={<ProductApplication />} path={'/solutions/:slug'} />
              </>
            ) : (
              // Redirect to the ComingSoon page if username is not provided or incorrect
              <Route path="*" element={<Navigate to="/coming-soon" />} />
            )}
          </Routes>
          {storedUsername === envUsername && <Footer />}
        </div>
      </Router>
    </>
  );
}

export default App;
